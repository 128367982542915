import { Routes, Route } from "react-router-dom";
import ClarifyHome from "./pages/ClarifyHome";
import SummaryDetail from "./pages/SummaryDetail";

function ClarifyApp() {
  return (
    <Routes>
      <Route path="/" element={<ClarifyHome />} />
      <Route path="/summary/:id" element={<SummaryDetail />} />
    </Routes>
  );
}

export default ClarifyApp; 