import Container from "@mui/material/Container";
import { Box, Typography, Grid, Card, CardContent, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Summary } from '../types/Summary';
import FullScreenBox from '../../components/FullScreenBox';

const mockSummaries: Summary[] = [
  {
    id: "1",
    title: "Trump 2025 Presidential Inauguration Speech",
    sourceUrl: "https://www.youtube.com/watch?v=BNArBr_J8mA&t=27s&ab_channel=CNN",
    visualizationUrl: "https://example.com/viz1",
    summary: "Inauguration speech following the 2024 presidential election, addressing the nation from Washington D.C.",
    keyPoints: ["National Security", "Economic Policy", "Government Reform"],
    dateAdded: "2025-01-20"
  }
];

function ClarifyHome() {
  return (
    <FullScreenBox>
      <Container 
        component="main" 
        maxWidth={false} 
        sx={{ 
          padding: 4,
          maxWidth: '1800px' // Custom max width
        }}
      >
        <Typography variant="h2" gutterBottom>
          Clarify
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
          Just summaries, no views
        </Typography>
        <Grid container spacing={3}>
          {mockSummaries.map((summary) => (
            <Grid item xs={12} sm={6} md={4} key={summary.id}>
              <Card 
                component={RouterLink} 
                to={`/clarify/summary/${summary.id}`}
                sx={{ 
                  textDecoration: 'none',
                  height: '100%',
                  '&:hover': { transform: 'scale(1.02)' },
                  transition: 'transform 0.2s'
                }}
              >
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {summary.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {summary.summary}
                  </Typography>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 2 
                  }}>
                    <Typography variant="caption" color="text.secondary">
                      January 20, 2025
                    </Typography>
                    <Link 
                      href={summary.sourceUrl}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                      sx={{ 
                        color: 'primary.main',
                        textDecoration: 'none',
                        '&:hover': { textDecoration: 'underline' }
                      }}
                    >
                      View on YouTube
                    </Link>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </FullScreenBox>
  );
}

export default ClarifyHome; 