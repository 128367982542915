import { Box, Typography, Paper, List, ListItem, Container, Link, IconButton } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import FullScreenBox from "../../components/FullScreenBox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const sections = [
  {
    title: "National Security and Immigration",
    points: [
      "Declare a national emergency at the southern border and halt all illegal entry",
      "Reinstate the Remain in Mexico policy and end catch and release",
      "Send troops to the southern border to repel what is described as a \"disastrous invasion\"",
      "Designate cartels as foreign terrorist organizations",
      "Use federal and state law enforcement to eliminate foreign gangs and criminal networks"
    ]
  },
  {
    title: "Economic Policy",
    points: [
      "Declare a national energy emergency",
      "Increase drilling for oil and gas",
      "Fill strategic reserves and export American energy",
      "End the Green New Deal and revoke the electric vehicle mandate",
      "Overhaul the trade system to protect American workers",
      "Establish an External Revenue Service to collect tariffs from foreign countries"
    ]
  },
  {
    title: "Government Reform",
    points: [
      "Establish a Department of Government Efficiency",
      "Stop government censorship and bring back free speech",
      "Restore fair, equal, and impartial justice under the constitutional rule of law",
      "End the government policy of socially engineering race and gender",
      "Establish that there are only two genders, male and female",
      "Reinstate service members unjustly expelled for objecting to the Covid vaccine mandate",
      "Stop military personnel from being subjected to radical political theories and social experiments"
    ]
  },
  {
    title: "National Identity & Global Influence",
    points: [
      "Change the name of the Gulf of Mexico to the Gulf of America",
      "Restore the name Mount McKinley to its original name",
      "Take back the Panama Canal due to broken promises",
      "Launch American astronauts to plant the stars and stripes on Mars"
    ]
  },
  {
    title: "General Goals",
    points: [
      "Restore national pride and respect",
      "Make America a manufacturing nation again",
      "Create a nation that is proud, prosperous, and free",
      "End all wars and bring a new spirit of unity",
      "Rebuild the nation to make it greater than ever before"
    ]
  }
];

function SummaryDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <FullScreenBox>
      <Container maxWidth={false} sx={{ padding: 4, maxWidth: '1800px' }}>
        <IconButton 
          onClick={() => navigate('/clarify')}
          sx={{ mb: 2 }}
        >
          <ArrowBackIcon />
        </IconButton>
        
        <Typography variant="h3" gutterBottom>
          Trump Presidential Inauguration Speech 2025 - Policy Priorities
        </Typography>
        
        <Box sx={{ mb: 4 }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Link 
              href="https://www.youtube.com/watch?v=BNArBr_J8mA&t=27s&ab_channel=CNN" 
              target="_blank"
              sx={{ 
                color: 'primary.main',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' }
              }}
            >
              View on YouTube
            </Link>
            <Typography variant="body2" color="text.secondary">
              January 20, 2025
            </Typography>
          </Box>
        </Box>

        <Box sx={{ mt: 4 }}>
          {sections.map((section) => (
            <Paper 
              key={section.title} 
              sx={{ 
                p: 3, 
                mb: 3,
                backgroundColor: 'background.paper',
                boxShadow: 2
              }}
            >
              <Typography 
                variant="h5" 
                gutterBottom 
                color="primary"
                sx={{ fontWeight: 'medium' }}
              >
                {section.title}
              </Typography>
              <List>
                {section.points.map((point, index) => (
                  <ListItem 
                    key={index}
                    sx={{ 
                      pl: 2,
                      py: 0.5,
                      '&::before': {
                        content: '"•"',
                        position: 'absolute',
                        left: 0,
                        color: 'primary.main'
                      },
                      display: 'flex',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Typography sx={{ display: 'inline' }}>{point}</Typography>
                  </ListItem>
                ))}
              </List>
            </Paper>
          ))}
        </Box>
      </Container>
    </FullScreenBox>
  );
}

export default SummaryDetail; 