import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import { ThemeProvider } from "@mui/material/styles";
import { lightTheme, darkTheme } from "./theme";
import { CssBaseline, useMediaQuery } from "@mui/material";
import LoginPage from "./pages/Login";
import { AuthProvider } from "./contexts/AuthContext";
import PlugDetails from "./pages/PlugDetails";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import { PlugsProvider } from "./contexts/PlugsContext";
import CreatePlug from "./pages/CreatePlug";
import AccountManagement from "./pages/AccountManagement";
import PastPlugs from "./pages/PastPlugs";
import PatchNotes from "./pages/PatchNotes";
import DeleteAccount from "./pages/DeleteAccount";
import ClarifyApp from "./clarify/ClarifyApp";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = prefersDarkMode ? darkTheme : lightTheme;

  return (
    <AuthProvider>
      <PlugsProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Routes>
              <Route path="/clarify/*" element={<ClarifyApp />} />
              <Route path="/" element={<LandingPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/login/:plugId" element={<LoginPage />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/plug/:plugId" element={<PlugDetails />} />
              <Route path="/create-plug" element={<CreatePlug />} />
              <Route path="/account" element={<AccountManagement />} />
              <Route path="/past-plugs" element={<PastPlugs />} />
              <Route path="/patch-notes" element={<PatchNotes />} />
              <Route
                path="/delete-account-mobile"
                element={<DeleteAccount />}
              />
            </Routes>
            <Footer />
          </Router>
        </ThemeProvider>
      </PlugsProvider>
    </AuthProvider>
  );
}

export default App;
